import { defineStore } from "pinia"

const getDefaultState = () => ({
  resourcesItems: [], // Inicializado como array vacío
  calendarEvents: null,
  resourcesFiltered: [], // Inicializado como array vacío
  eventStored: null,
  calendarSettings: null,
  whatsAppInstances: null,
  addDurationToServicesInAppointment: 1,
});

export const useSchedulerStore = defineStore("scheduler", {
  state: getDefaultState,
  persist: true,
  actions: {

    setResourcesItems(resourcesItems) {
      this.resourcesItems = resourcesItems;
    },

    setResourcesSelected(resourcesFiltered) {
      this.resourcesFiltered = resourcesFiltered;
    },

    setCalendarEvents(calendarEvents) {
      this.calendarEvents = calendarEvents;
    },

    setAddDurationToServicesInAppointment(addDuration){
      this.addDurationToServicesInAppointment = addDuration
    },
    setWhatsAppInstances(wsInstances) {
      this.whatsAppInstances = wsInstances;
    },

    setEvent(event) {
      this.eventStored = event;
    },

    setCalendarSettings(calendarSettings){
      this.calendarSettings = calendarSettings
    },

    setLogout() {
      // Restablece el estado a su estado inicial
      Object.assign(this, getDefaultState());
    }

  },
  getters: {
    getEventStored: (state) => state.eventStored,
    getResourcesItems: (state) => state.resourcesItems,
    getcalendarEvents: (state) => state.calendarEvents,
    getWhatsAppInstances: (state) => state.whatsAppInstances,
  },
});
